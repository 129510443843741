import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link as RouterLink, useHistory } from "react-router-dom";
import { login } from "../authSlice";
import classy from "./loginform.module.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const LoginForm = ({ disableLogin }) => {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const history = useHistory();
  const [isExisted, setIsExisted] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // const checkSuperAdmin = () => {
  //   axios
  //     .get("/Security/superAdminExisted")
  //     .then((res) => {
  //       if (res.data) {
  //         setIsExisted(true);
  //       } else {
  //         setIsExisted(false);
  //         history.replace("/signup");
  //       }
  //       localStorage.setItem("license", true);
  //     })
  //     .catch((err) => {
  //       toast.error(err);
  //     });
  // };

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!!localStorage.phone && !!localStorage.password) {
        setPhone(localStorage.getItem("phone"));
        setPassword(localStorage.getItem("password"));
        setIsChecked(true);
      }
      // checkSuperAdmin();
    }
    return () => {
      mounted = false;
      setIsExisted(false);
    };
  }, []);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (phone && password) {
      dispatch(login({ phone, password })).then(({ payload }) => {
        if (payload) {
          if (isChecked) {
            localStorage.setItem("phone", phone);
            localStorage.setItem("password", password);
          } else {
            localStorage.removeItem("phone");
            localStorage.removeItem("password");
          }
          localStorage.setItem("license", true);
        }
      });
    }
  };

  const loginError = useSelector((state) => state.auth.loginError);
  const loginStatus = useSelector((state) => state.auth.loginStatus);

  return (
    <form>
      <Box
        component="main"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <Container maxWidth="xs">
          <div className={classy.boxLogin}>
            <div className={classy.regholderLogin}>
              <TextField
                fullWidth
                label="رقم الهاتف"
                margin="normal"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                variant="outlined"
                style={{ backgroundColor: "#fff", borderColor: "#7a1f1d" }}
              />

              <TextField
                fullWidth
                helperText={Boolean(loginError) ? loginError : ""}
                error={Boolean(loginStatus === "failed")}
                label="كلمة المرور"
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                style={{ backgroundColor: "#fff", borderColor: "#7a1f1d" }}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ color: "#7a1f1d" }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Box sx={{ py: 1 }}>
                <Button
                  className={classy.btnLogin}
                  size="large"
                  type="submit"
                  fullWidth
                  onClick={handleSubmit}
                  endIcon={
                    loginStatus === "loading" && (
                      <CircularProgress size={24} style={{ color: "#DDD" }} />
                    )
                  }
                  disabled={loginStatus === "loading" || disableLogin}
                  variant="contained"
                >
                  تسجيل الدخول
                </Button>
              </Box>
              {/* {!isExisted && (
              <Typography color="textSecondary" variant="body2">
                ليس لديك حساب؟
                <Link
                  component={RouterLink}
                  to="/signup"
                  style={{ color: "#333" }}
                  variant="subtitle2"
                  underline="hover"
                >
                  تسجيل حساب
                </Link>
              </Typography>
            )} */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "1rem",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      value="remember"
                      checked={isChecked}
                      style={{ color: "black" }}
                    />
                  }
                  label="تذكرني لاحقا"
                  onChange={() => setIsChecked(!isChecked)}
                />
              </div>
            </div>
          </div>
        </Container>
      </Box>
      {/* <Box sx={{ py: 2 }}>
        <Button
          className={classy.btn}
          size="large"
          type="submit"
          fullWidth
          onClick={() => {
            history.replace("./security");
          }}
          endIcon={
            loginStatus === "loading" && (
              <CircularProgress size={24} style={{ color: "#DDD" }} />
            )
          }
          variant="contained"
        >
          security
        </Button>
      </Box> */}
    </form>
  );
};

export default LoginForm;
