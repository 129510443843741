import { Fragment, useState, useEffect, useRef } from "react";
import "./Navitem.css";
import { BsCircle } from "react-icons/bs";
import { TiChevronLeft } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";
import { Link } from "react-router-dom";

const Collapse = (props) => {
  const [openedindex, setopenedindex] = useState(""); // تتبع العنصر المفتوح
  const [activeSubItem, setActiveSubItem] = useState(null); // تتبع العنصر النشط داخل القائمة الداخلية

  const navRef = useRef(null);

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      if (!props.isopen || openedindex === "") {
        setopenedindex("");
        setActiveSubItem(null); // إعادة تعيين العنصر النشط عند إغلاق القائمة
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props.isopen, openedindex]);

  const handlecollapse = (index) => {
    if (index === openedindex) {
      setopenedindex("");
    } else {
      setopenedindex(index);
    }
  };

  const handleSubItemClick = (index) => {
    setActiveSubItem(index); // تعيين العنصر النشط عند النقر
    if (!props.isopen) {
      setopenedindex(""); // إغلاق القائمة المنسدلة فقط إذا كانت القائمة الأساسية مغلقة
    }
  };
  
  

  let stylo = props.isopen ? "open" : "nestedopen";

  return (
    <Fragment>
      <div
        ref={navRef}
        className={props.isopen ? "navholderexpand" : "navholder"}
      >
        {props.lists.map((item, index) => (
          <div key={index}>
            <div
              className="btn"
              style={{ borderBottom: "1px solid #F3C637" }}
              onClick={() => {
                handlecollapse(index);
              }}
            >
              <div
                className={props.isopen ? "iconHandler" : "iconHandleractive"}
              >
                <item.icon />
              </div>
              {props.isopen && <p key={index}>{item.list}</p>}

              {openedindex !== index
                ? props.isopen && (
                    <TiChevronLeft
                      style={{ color: "#FFDA00", fontSize: "1.5vw" }}
                    />
                  )
                : props.isopen && (
                    <TiArrowSortedDown
                      style={{ color: "#FFDA00", fontSize: "1.5vw" }}
                    />
                  )}
            </div>
            <div className="collapseholder ">
              <div
                className={`${stylo} ${
                  openedindex !== index ? "collapse" : ""
                }`}
              >
                {item.items.map((nested, idx) =>
                  !!nested.href ? (
                    <Link
                      onClick={() => handleSubItemClick(`${index}-${idx}`)} // تحديث العنصر النشط عند النقر
                      to={nested.href}
                      key={`${index}-${idx}`}
                    >
                      <div
                        className={`nested ${
                          activeSubItem === `${index}-${idx}` ? "active" : ""
                        }`} // تطبيق النمط النشط
                      >
                        <div>
                          <BsCircle className="icon" />
                        </div>
                        <p
                          style={{
                            color: "#181301",
                            paddingRight: 0,
                            marginRight: 0,
                            fontSize: 14,
                          }}
                        >
                          {nested.title}
                        </p>
                      </div>
                    </Link>
                  ) : (
                    <Link
                      onClick={() => handleSubItemClick(`${index}-${idx}`)} // تحديث العنصر النشط عند النقر
                      to={{ pathname: nested.blank }}
                      target="_blank"
                      key={`${index}-${idx}`}
                    >
                      <div
                        className={`nested ${
                          activeSubItem === `${index}-${idx}` ? "active" : ""
                        }`} // تطبيق النمط النشط
                      >
                        <div>
                          <BsCircle className="icon" />
                        </div>
                        <p
                          style={{
                            color: "#181301",
                            paddingRight: 0,
                            marginRight: 0,
                            fontSize: 14,
                          }}
                        >
                          {nested.title}
                        </p>
                      </div>
                    </Link>
                  )
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default Collapse;
