import React from "react";
import axios from "axios";
import Alerto from "../../components/UI/toaster";

function BannerImg(props) {
  const [banner, setBanner] = React.useState("");

  const getBanner = () => {
    axios
      .get("/Setting/indexImage")
      .then((res) => {
        setBanner(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      getBanner();
    }
    return () => {
      mounted = false;
    };
  }, []);

  return !!banner ? (
    <img
      style={{ width: "100%", height: "auto", boxShadow: " white 0px 0px 7px" }}
      src={`data:image/jpeg;base64,${banner}`}
      className="rounded-3"
      alt="1 صورة الشركة"
      {...props}
    />
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    ></div>
  );
}
export default BannerImg;

/*
import React from "react";
import axios from "axios";
import Alerto from "../../components/UI/toaster";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: `calc(100vh - 64px)`,
    width: "100%",
    backgroundSize: "contain",
    // backgroundPosition: "center",
    backgroundRepeat: "round",
    backgroundImage: (props) => `url(data:image/jpeg;base64,${props.banner})`,
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -1,
  },
}));

function BannerImg(props) {
  const [banner, setBanner] = React.useState("");
  
  const getBanner = () => {
    axios
    .get("/Setting/indexImage")
    .then((res) => {
      setBanner(res.data);
      })
      .catch((err) => {
        Alerto(err);
      });
    };
    
    React.useEffect(() => {
    getBanner();
  }, []);

  const classes = useStyles({ banner });

  return !!banner ? (
    <div className={clsx(classes.root)} {...props}></div>
  ) : (
    <div
    style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      >
      </div>
    );
  }

  export default BannerImg;
  */
