import axios from "axios";
import { useEffect, useState } from "react";
import Alerto from "../../../components/UI/toaster";
import { toast } from "react-toastify";
import classes from "./limit.module.css";
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import Mydate from "../../../components/Helpers/MyDateParser";
import CounterOfSubscription from "./CounterOfSubscription";

const Limit = () => {
  const isLocal = useSelector((state) => state.auth.isLocal);
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [subsTime, setSubsTime] = useState(new Date());
  const [selectedPackage, setSelectedPackage] = useState("");
  const [packages, setPackages] = useState([]);

  function getSettings() {
    axios
      .get("Setting/generalSettings")
      .then((res) => {
        setValue(res.data.openingBalance);
        setValue2(res.data.latingLimitInDaysForBuyers);
        setSubsTime(new Date(res.data.subscribeUntill));
        setSelectedPackage(res.data.subscribtionType);
      })
      .catch((e) => {
        Alerto(e);
      });
  }

  function getPackages() {
    axios
      .get("/Setting/subscribtion/types")
      .then((res) => {
        setPackages(res.data);
      })
      .catch((e) => {
        Alerto(e);
      });
  }

  useEffect(() => {
    getSettings();
    getPackages();
  }, []);

  const update = () => {
    if (value === "" || value2 === "") {
      toast.error("يرجي ملئ جميع الخانات ");
    } else {
      axios
        .put("Setting/generalSettings", {
          openingBalance: value,
          latingLimitInDaysForBuyers: value2,
          subscribeUntill: !subsTime ? null : Mydate(subsTime),
          subscribtionType: +selectedPackage,
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success("تم التعديل بنجاح");
          }
        })
        .catch((err) => {
          Alerto(err);
        });
    }
  };

  const initializeGovs = () => {
    axios
      .post(`Setting/uploadGovsAndCities`)
      .then((res) => {
        if (res.status === 200) {
          toast.success("تمت العملية بنجاح");
        }
      })
      .catch((err) => {
        Alerto(err);
      });
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderRadius: "10px",
        padding: "20px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        marginBottom: "20px" 
      }}
    >
      <Grid container spacing={2} style={{ marginBottom: "3rem" }}>
        <Grid item xs={7} md={4} lg={2} >
          <TextField
            label="رصيد"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            variant="outlined"
            type="number"
            fullWidth
            size="small"
            InputProps={{
              style: { fontSize: "large", height: "2.5rem" },
            }}
          />
        </Grid>
        <Grid item xs={5} md={2} lg={2} >
          <TextField
            label="متأخرات"
            value={value2}
            onChange={(e) => setValue2(e.target.value)}
            variant="outlined"
            type="number"
            fullWidth
            size="small"
            InputProps={{
              style: { fontSize: "large", height: "2.5rem" },
            }}
          />
        </Grid>
        {!isLocal && (
          <SubDate
            packages={packages}
            setSelectedPackage={setSelectedPackage}
            selectedPackage={selectedPackage}
            setSubsTime={setSubsTime}
            subsTime={subsTime}
          />
        )}
        <Grid item xs={4} md={3} lg={1} >
          <button onClick={update} className={classes.btn}>
            تعديل
          </button>
        </Grid>
        <Grid item xs={8} md={3} lg={3} >
          <button onClick={initializeGovs} className={classes.btn}>
            تحميل المحافظات
          </button>
        </Grid>
      </Grid>
      <CounterOfSubscription subsTime={subsTime} />
    </div>
  );
};

function SubDate({
  subsTime,
  setSubsTime,
  packages,
  setSelectedPackage,
  selectedPackage,
}) {
  return (
    <>
      <Grid item xs={7} md={6} lg={2} >
        <DatePicker
          locale="ar"
          selected={subsTime}
          onChange={setSubsTime}
          dateFormat="dd/MM/yyyy"
          customInput={
            <TextField
              size="small"
              style={{
                backgroundColor: "#fff",
                height: "3rem",
              }}
              variant="outlined"
              label="تاريخ الاشتراك"
            />
          }
        />
      </Grid>
      <Grid item xs={5} md={3} lg={2} >
        <FormControl fullWidth>
          <InputLabel>نوع الباقة</InputLabel>
          <Select
            value={selectedPackage}
            onChange={(e) => setSelectedPackage(e.target.value)}
            style={{
              height: "2.5rem",
              fontSize: "16px",
            }}
          >
            {packages.map((ele) => (
              <MenuItem key={ele.id} value={ele.id}>
                {ele.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}

export default Limit;
