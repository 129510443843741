import { CardContent, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "80% !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "50%",
    },
    // [theme.breakpoints.up("sm")]: {
    //   width: "50%",
    // },
  },
}));

function MainHeader({ title }) {
  const classes = useStyles();
  return (
    <CardContent
      className={clsx(classes.mainTitle, " headerSearch m-auto mt-3")}
    >
      <Typography variant="h5" className="text-black text-center ">
        {title}
      </Typography>
    </CardContent>
  );
}

export default MainHeader;
