import { useState, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
  countDown: {
    width: "100%",
    justifyContent: "center",
    flexDirection: "row-reverse",
    padding: "20px 10px",
    margin: "1rem auto",
  },
  countTime: {
    border: "1px solid #000",
    justifyContent: "center",
    margin: "10px",
    padding: "0  !important",
    borderRadius: "10px 10px 0 0",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  countTimeNum: {
    height: "70px",
    background: "#01111e",
    color: "#fff",
    padding: "5px",
    textAlign: "center",
    fontSize: "3rem",
    borderRadius: "10px 10px 0 0",
  },
  countTimeText: {
    height: "35px",
    background: "#ff0",
    color: "#000",
    padding: "5px",
    margin: "0",
    textAlign: "center",
    fontSize: "1rem",
  },
}));

const CounterOfSubscription = ({ subsTime }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const countDownDate = useMemo(() => new Date(subsTime).getTime(), [subsTime]);
  // const countDownDate = new Date(subsTime).getTime();
  //   console.log(countDownDate); //test
  // console.log(subsTime); //test
  useEffect(() => {
    const timerId = setInterval(() => {
      const nowTime = new Date().getTime();
      const distance = (countDownDate - nowTime) / 1000;
      if (distance > 0) {
        setDays(Math.floor(distance / (60 * 60 * 24)));
        setHours(Math.floor((distance / (60 * 60)) % 24));
        setMinutes(Math.floor((distance / 60) % 60));
        setSeconds(Math.floor(distance % 60));
      } else {
        clearInterval(timerId);
      }
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, [countDownDate]);

  const classes = useStyles();

  return (
    <Box sx={days < 2 ? { background: "red " } : null}>
      <Grid container spacing={2} className={classes.countDown}>
        <Grid
          item
          xs={3}
          sm={2}
          md={days >= 100 ? 2 : 1}
          className={classes.countTime}
        >
          <div className={classes.countTimeNum}>{days}</div>
          <p className={classes.countTimeText}>يوم</p>
        </Grid>
        <Grid item xs={3} sm={2} md={1} className={classes.countTime}>
          <div className={classes.countTimeNum}>{hours}</div>
          <p className={classes.countTimeText}>ساعة</p>
        </Grid>
        <Grid item xs={3} sm={2} md={1} className={classes.countTime}>
          <div className={classes.countTimeNum}>{minutes}</div>
          <p className={classes.countTimeText}>دقيقة</p>
        </Grid>
        <Grid item xs={3} sm={2} md={1} className={classes.countTime}>
          <div className={classes.countTimeNum}>{seconds}</div>
          <p className={classes.countTimeText}>ثانية</p>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CounterOfSubscription;
