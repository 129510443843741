import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { BsCircle, BsPersonFill, BsCreditCardFill } from "react-icons/bs";
import { TiArrowSortedDown } from "react-icons/ti";
import "../../../components/Navitem.css";
import { CSSTransition } from "react-transition-group";

const AvatarInHeader = ({
  role,
  PartnerId,
  partner,
  partnerBuyerId,
  openAvatar,
  handleAvatarList,
}) => {
  const [openedIndex, setOpenedIndex] = useState(null);
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      handleAvatarList();
      setOpenedIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getProfileLink2 = () => {
    if (role === "عميل_او_بائع" || role === "محصل") {
      return `/client-user-profile/${PartnerId}`;
    } else if (partnerBuyerId === 1 && partner) {
      return `/client-profile/${PartnerId}`;
    } else if (partner) {
      return `/seller-profile/${PartnerId}`;
    }
    return "#";
  };

  const getProfileLink = () => {
    if (role === "عميل_او_بائع" || role === "محصل") {
      return `/client-user-profile/${PartnerId}`;
    } else if (partnerBuyerId === 1 && partner) {
      return `/add-partner/client/${PartnerId}`;
    } else if (partner) {
      return `/add-partner/seller/${PartnerId}`;
    }
    return "#";
  };

  const handleCollapse = (index) => {
    setOpenedIndex(index === openedIndex ? null : index);
  };

  const menuItems = [
    {
      list: "حسابي",
      icon: BsPersonFill,
      items: [
        ...(role !== "محصل" && role !== "عميل_او_بائع"
          ? [{ title: "بياناتي الشخصية", href: getProfileLink() }]
          : []),

        { title: "تفاصيل الحسابات", href: getProfileLink2() },
      ],
    },
    ...(role !== "محصل" && role !== "عميل_او_بائع" && role !== "موظف"
      ? [
          {
            list: "الاشتراك",
            icon: BsCreditCardFill,
            items: [
              { title: "تفاصيل الاشتراك", href: "" },
              { title: "تجديد الاشتراك", href: "" },
            ],
          },
        ]
      : []),
  ];

  const [styles, setStyles] = useState({
    fontSize: "1.5vw",
    gap: "20px",
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setStyles({ fontSize: "3vw", gap: "12px" });
      } else {
        setStyles({ fontSize: "1.5vw", gap: "30px" });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    openAvatar && (
      <CSSTransition
        in={openAvatar}
        timeout={3000} // مدة التأثير 3 ثوانٍ
        classNames={{
          enter: "menu-enter",
          enterActive: "menu-enter-active",
          exit: "menu-exit",
          exitActive: "menu-exit-active",
        }}
        unmountOnExit
      >
        <div
          ref={menuRef}
          className="navholderexpand avatar-menu"
          style={{
            position: "absolute",
            top: "58px",
            left: "-14px",
            borderRadius: "10px",
            zIndex: 1000,
            maxWidth: "200px",
            transformOrigin: "top center",
          }}
        >
          {menuItems.map((item, index) => (
            <div key={index}>
              <div
                className="btn"
                style={{
                  borderBottom: "1px solid #F3C637",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize:"14px"
                }}
                onClick={() => handleCollapse(index)}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: styles.gap }}
                >
                  <item.icon style={{ color: "#FFDA00", fontSize:  styles.fontSize }} />
                  <p>{item.list}</p>
                </div>

                <TiArrowSortedDown
                  style={{
                    color: "#FFDA00",
                    fontSize: styles.fontSize ,
                    transform:
                      openedIndex === index ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                  }}
                />
              </div>

              <div
                className="collapseholder"
                style={{
                  maxHeight: openedIndex === index ? "300px" : "0",
                  overflow: "hidden",
                  transition: "max-height 0.3s ease",
                }}
              >
                {item.items.map((nested, idx) => (
                  <Link
                    to={nested.href}
                    key={`${index}-${idx}`}
                    onClick={() => handleAvatarList()}
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      className="nested"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0px",
                        padding: "8px",
                        color: "#181301",
                      }}
                    >
                       <BsCircle className="icon" style={{padding:"5",  margin:"10"}}  />
                      <p style={{ margin: 0, fontSize: "13px" }}>
                        {nested.title}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </CSSTransition>
    )
  );
};

export default AvatarInHeader;
