import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  CardContent,
  Grid,
  Card as MUICard,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Database } from "react-feather";
import CircleIcon from "@mui/icons-material/Circle";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import clsx from "clsx";

import BannerImg from "./BannerImg";
import Card from "./components/Card";
import qr from "../../assets/images/qr.png";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MusicNoteIcon from "@mui/icons-material/MusicNote"; // for TikTok

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: "relative",
    minHeight: `calc(100vh - 64px)`,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    zIndex: 1,
    textAlign: "center",
    padding: theme.spacing(3),
    // background: "rgba(0, 0, 0, 0.1)",
  },
  displayDownBanner: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  displayUpBanner: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  // button: {
  //   marginTop: theme.spacing(2),
  //   color: 'white',
  //   borderColor: 'white',
  // },
}));

const Home = () => {
  const classes = useStyles();
  const role = localStorage.getItem("role");
  const cardData = [
    {
      id: 2,
      title: "النقلات",
      actions: [
        { name: "اضف نقلة", link: "/loads" },
        { name: "نقلات اليوم", link: "/daily-trucks" },
        { name: "نقلات متبقية", link: "/remaining-trucks" },
        { name: "نقلات منتهية", link: "/completed-trucks" },
      ],
    },
    {
      id: 1,
      title: "الفواتير",
      Icon: Database,
      actions: [
        { name: "فواتير البائعين", link: "/sellers-invoices" },
        { name: "فواتير العمولات", link: "/commission-invoices" },
        { name: "فواتير المشتريات", link: "/purchase-invoices" },
      ],
    },
    {
      id: 3,
      title: "العده و الفوارغ",
      actions: [
        { name: "اضف خارج", link: "/tools-out" },
        { name: "اضف تحصيل", link: "/tools-in" },
        { name: "اضف هالك", link: "/damage-tools" },
      ],
    },
    {
      id: 4,
      title: "الخزينة",
      actions: [
        { name: "اضف تحصيل", link: "/add-deposit" },
        { name: "اضف مصروفات", link: "add-expenses" },
      ],
    },
  ];

  const renderCards = () => (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        md={9}
        className={clsx(classes.displayDownBanner, "mt-3")}
      >
        <BannerImg />
      </Grid>
      {cardData.map((item) => (
        <Grid key={item.id} item xs={12} sm={6} xl={3}>
          <Card {...item} />
        </Grid>
      ))}
    </Grid>
  );

  const renderAccountsCard = () => (
    <>
      {role === "عميل_او_بائع" ? (
        <CardContent className="text-start"></CardContent>
      ) : (
        <MUICard
          style={{
            background:
              "linear-gradient(to top,#0d8ff2 30% , #43aeff  , #b1ddff   , #43aeff ,#097fd8  )",
            boxShadow:
              "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset",
            textAlign: "center",
            height: "100%",
            width: "100%",
            color: "#FFF",
            cursor: "default",
            borderRadius: "24px 0 0 0",
            paddingBottom: "1rem",
          }}
          className="hoverCard"
        >
          <CardContent className=" text-start">
            <Typography
              className=" center w-100 bg-global globalFont"
              style={{ height: "60px" }}
            >
              <SupervisorAccountOutlinedIcon style={{ marginLeft: "5px" }} />
              الحسابات
            </Typography>
            {renderAccountLinks()}
          </CardContent>
        </MUICard>
      )}
    </>
  );

  const renderAccountLinks = () => {
    const links = [
      { to: "/profiles/clients", text: "حسابات عملاء" },
      { to: "/clients-collecting-addresses", text: "كشف تحصيل عملاء" },
      { to: "/profiles/sellers", text: "حسابات بائعين" },
      { to: "/buyers-collecting-addresses", text: "كشف تحصيل بائعين" },
    ];

    return links.map((link, index) => (
      <Link
        key={index}
        to={link.to}
        style={{ color: "black", padding: "8px 4px" }}
        className="center justify-content-start px-3 text-black py-1 mt-3 w-100 linkHover"
      >
        <div>
          <CircleIcon style={{ fontSize: "8px", marginLeft: "3px" }} />
        </div>
        {link.text}
      </Link>
    ));
  };

  const getWhatsAppLink = () => {
    const phoneNumber = "+201006375557";
    const systemName = window.location.hostname;
    const message = `${systemName}\n سلام عليكم،أود الاستفسار عن `;

    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    if (isMobile) {
      return `whatsapp://send?phone=${phoneNumber}&text=${encodeURIComponent(
        message
      )}`;
    } else {
      return `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
        message
      )}`;
    }
  };

  const getSocialMediaLink = (platform) => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

    const platformLinks = {
      facebook: {
        mobile: "fb://page/OwlTech2012",
        web: "https://www.facebook.com/OwlTech2012"
      },
      youtube: {
        mobile: "youtube://www.youtube.com/channel/UC4HINSLHHDsMNq28eArGoeg", // Replace with actual YouTube channel ID
        web: "https://www.youtube.com/@OwlTechEG"
      },
      instagram: {
        mobile: "instagram://user?username=OwlTechEG",
        web: "https://www.instagram.com/OwlTechEG"
      },
      twitter: {
        mobile: "twitter://user?screen_name=Owltech2012",
        web: "https://twitter.com/Owltech2012"
      },
      tiktok: {
        mobile: "https://www.tiktok.com/@OwlTechEG", // TikTok app deep link
        web: "https://www.tiktok.com/@OwlTechEG"
      }
    };

    const platformData = platformLinks[platform];
    return isMobile ? platformData.mobile : platformData.web;
  };

  const renderCustomerSupport = () => (
    <div
      style={{
        background:
          "linear-gradient(to top, #f57542, #FFA500, #F3C637 70%, #FFD700, #FFD700,#FFD700, #FFA500)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 12,
        borderRadius: "0px 0px 24px 0px",
        boxShadow:
          "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px,rgb(0 0 0 / 20%) 0px -3px 0px inset",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", marginBottom: 12 }}>
        <div>
          <Typography
            style={{ fontWeight: "bold", fontSize: 18, textAlign: "left" }}
          >
            Contact Support
          </Typography>
          <Typography
            style={{
              direction: "ltr",
              fontWeight: "bold",
              fontSize: 18,
              textAlign: "center",
              letterSpacing: "2px", 
            }}
          >
            <a
              href="tel:+201006375557"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              +201006375557
            </a>
          </Typography>


        </div>
        <PhoneInTalkIcon style={{ fontSize: 36, marginLeft: 12 }} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          backgroundColor: "white",
          padding: "3px",
          borderRadius: "8px",
          boxShadow: "inset 0 0 5px rgba(0,0,0,0.2)",
        }}
      >
        <a
          href={getSocialMediaLink('facebook')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon style={{ color: "#1877F2", fontSize: 28 }} />
        </a>
        <a
          href={getSocialMediaLink('youtube')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <YouTubeIcon style={{ color: "#FF0000", fontSize: 28 }} />
        </a>
        <a
          href={getSocialMediaLink('instagram')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon style={{ color: "#E4405F", fontSize: 28 }} />
        </a>
        <a
          href={getSocialMediaLink('twitter')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TwitterIcon style={{ color: "#1DA1F2", fontSize: 28 }} />
        </a>
        <a
          href={getSocialMediaLink('tiktok')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <MusicNoteIcon style={{ color: "#000000", fontSize: 28 }} />
        </a>
        <a 
          href={getWhatsAppLink()} 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <WhatsAppIcon style={{ color: "#25D366", fontSize: 28 }} />
        </a>
      </div>
    </div>
  );

  return (
    <div
      className="w-100 margin15px"
      style={{
        background: "#534d3b",
        marginTop: "-15px !important",
        padding: "1.5rem 1.5rem 3rem",
      }}
    >
      <Helmet>
        <title>الرئيسية</title>
      </Helmet>

      {role !== "محصل" && role !== "عميل_او_بائع" && renderCards()}

      <Grid container spacing={2} alignItems="flex-start" className="mx-auto">
        <Grid
          item
          lg={9}
          xs={12}
          // className={clsx(classes.displayUpBanner, "mx-auto")}
          className={`${
            role === "محصل" || role === "عميل_او_بائع"
              ? "" 
              : classes.displayUpBanner
          } mx-auto`}
         
        >
          <BannerImg />
        </Grid>
        <Grid className="p-2" item xs={12} lg={3}>
          {renderAccountsCard()}
          <Grid
            item
            container
            spacing={1}
            xs={12}
            className="py-1 m-auto"
            style={{ margin: "0px" }}
          >
            <Grid item xs={12}>
              {renderCustomerSupport()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default Home;

//#F0284A
//#2ABBA7
//#1877F2
//#EF3C47

//#FF5672
//#FFB851
//#4D9EFF