import React from "react";
import { Link } from "react-router-dom";
import {
  CardContent,
  Grid,
  Card as MUICard,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CircleIcon from "@mui/icons-material/Circle";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import KitchenOutlinedIcon from "@mui/icons-material/KitchenOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    padding: 0,
  },
  title: {
    color: "#FFF",
    fontSize: 16,
    cursor: "default",
    transition: ".3s",
  },
  smallerFont: {
    fontSize: "0.82rem",
  },
}));

function Card({ id, title, actions }) {
  const classes = useStyles();

  const renderCardStyle = () => {
    switch (id) {
      case 1:
        return {
          background:
            "linear-gradient(to top, #fd3c3c, #fd6767, #FFADAD, #fd6767, #fd3c3c)",
        };
      case 2:
        return {
          background:
            "linear-gradient(to top, #f59206, #fbad3d, #f7d1a4, #fbad3d, #f59206)",
        };
      case 3:
        return {
          background:
            "linear-gradient(to top,#FFD400, #FFE000,#FDFFB6,   #FFE000, #FFD400)",
        };
      case 4:
        return {
          background:
            "linear-gradient(to top,#57f736, #84ff6b,#c4f7ba, #84ff6b, #57f736)",
        };
      default:
        return { backgroundColor: "transparent" };
    }
  };

  const renderIcon = () => {
    switch (title) {
      case "الفواتير":
        return <AssignmentOutlinedIcon style={{ marginLeft: "5px" }} />;
      case "النقلات":
        return <LocalShippingOutlinedIcon style={{ marginLeft: "5px" }} />;
      case "العده و الفوارغ":
        return <Inventory2OutlinedIcon style={{ marginLeft: "5px" }} />;
      case "الخزينة":
        return <KitchenOutlinedIcon style={{ marginLeft: "5px" }} />;
      default:
        return null;
    }
  };

  return (
    <MUICard
      className="hoverCard "
      style={{
        ...renderCardStyle(),
        boxShadow:
          "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset",
          height: "90%",
          width: "98%",
        borderRadius: "24px 0",
        margin: "0.5rem",
        transition: ".4s",
      }}
    >
      <CardContent >
        <Grid className="p-0">
          <Grid item className="p-0">
            <Typography
              className={`center w-100 bg-global globalFont ${classes.title} ${classes.smallerFont}`}
              style={{ height: "50px", marginBottom: "8px" }}
            >
              {renderIcon()}
              {title}
            </Typography>
          </Grid>
          <Grid item container spacing={2} style={{ padding: "0px 4px 8px" }}>
            {actions.map((action) => (
              <Grid key={action.name} item style={{ width: "50%" }}>
                <Link
                  to={action.link}
                  className={`center text-black justify-content-start pe-2 w-100 linkHover ${classes.smallerFont}`}
                  style={{ color: "black", padding: "5px 0px" }}
                >
                  <CircleIcon
                    style={{
                      fontSize: "8px",
                      margin: "0px 3px",
                    }}
                  />
                  {action.name}
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </MUICard>
  );
}

export default Card;
