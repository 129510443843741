import { Package, Settings } from "react-feather";
import "./Navitem.css";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { MdOutlineSupportAgent } from "react-icons/md";

import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import MoneyOutlinedIcon from "@material-ui/icons/MoneyOutlined";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import React, { useEffect } from "react";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import { Fragment } from "react";
import { useState } from "react";
import Collapse from "./Collapse";
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
const lists = [
  {
    icon: HomeOutlinedIcon,
    list: "بيانات أساسية",
    items: [
      { href: "/products", title: "اضف الصنف" },
      { href: "/movementTools", title: "اضف العدة" },
      { href: "/collecting-address", title: "اضف مكان التحصيل" },
      { href: "/add-partner/client", title: "اضف عميل   " },
      { href: "/add-partner/seller", title: "اضف بائع" },
      { href: "/partners", title: "قائمة  الاسماء " },
    ],
  },
  {
    icon: LocalShippingOutlinedIcon,
    list: "النقلات",
    items: [
      { href: "/loads", title: "اضف نقلة" },
      { href: "/daily-trucks", title: "نقلات اليوم" },
      { href: "/remaining-trucks", title: "نقلات متبقية" },
      { href: "/completed-trucks", title: "نقلات منتهية" },
    ],
  },
  {
    icon: FileCopyOutlinedIcon,
    list: "الفواتير",
    items: [
      { href: "/sellers-invoices", title: "فواتير البائعين" },
      { href: "/commission-invoices", title: "فواتير العموﻻت" },
      { href: "/purchase-invoices", title: "فواتير المشتريات" },
    ],
  },
  {
    icon: PeopleAltOutlinedIcon,
    list: "العملاء",
    items: [
      { href: "/profiles/clients", title: "حسابات العملاء" },
      { href: "/clients-collecting-addresses", title: "كشوف تحصيل العملاء" },
      { href: "/clients-arrears", title: "متأخرات العملاء" },
    ],
  },
  {
    icon: PeopleAltIcon,
    list: "البائعين",
    items: [
      { href: "/profiles/sellers", title: "حسابات البائعين" },
      { href: "/buyers-collecting-addresses", title: "كشوف تحصيل البائعين" },
      { href: "/buyers-arrears", title: "متأخرات البائعين" },
      { href: "/tools-arrears", title: "متأخرات العدة" },
    ],
  },
  {
    icon: Package,
    list: "العدة و الفوارغ",
    items: [
      { href: "/tools-out", title: "أضف خارج" },
      { href: "/tools-in", title: "أضف تحصيل" },
      { href: "/damage-tools", title: "أضف هالك" },
      { href: "/clients-reports", title: "تقارير العملاء" },
      { href: "/sellers-reports", title: "تقارير البائعين" },
      { href: "/tools-general-reports", title: "تقارير عامة" },
    ],
  },
  {
    icon: MoneyOutlinedIcon,
    list: "الخزينة",
    items: [
      { href: "/add-deposit", title: "اضف تحصيل" },
      { href: "/add-expenses", title: "اضف مصروفات" },
      { href: "/deposits", title: "التحصيل" },
      { href: "/search-expenses", title: "المصروفات" },
      { href: "/deposits-reports", title: "تقارير التحصيل" },
      { href: "/reports-expenses", title: "تقارير المصروفات" },
      { href: "/treasury-balance", title: "رصيد الخزينة" },
    ],
  },
  {
    icon: MonetizationOnOutlinedIcon,
    list: "الارباح",
    items: [
      { href: "/general-profits", title: "أرباح عامة" },
      { href: "/daily-profits", title: "أرباح يومية" },
      { href: "/monthly-profits", title: "أرباح شهرية" },
      { href: "/annual-profits", title: "أرباح سنوية" },
    ],
  },
  {
    icon: AccessTimeOutlinedIcon,
    list: "التقارير",
    items: [
      { href: "/general-reports", title: "تقارير مديونية" },
      { href: "/daily-reports", title: "تقارير يومية" },
      { href: "/monthly-reports", title: "تقارير شهرية" },
      { href: "/annual-reports", title: "تقارير سنوية" },
    ],
  },
  {
    icon: WorkOutlineOutlinedIcon,
    list: "الموظفين",
    items: [
      { href: "/add-employee", title: "اضف موظف" },
      // { href: "/add-job", title: "اضف وظيفة" },
      // { href: "/add-expenses/staff", title: "سلف موظفين" },
      { href: "/profiles/staff", title: "حسابات موظفين" },
    ],
  },
  {
    icon: GroupAddOutlinedIcon,
    list: "المستخدمين",
    items: [
      { href: "/signup", title: "اضف مستخدم" },
      { href: "/users", title: "المستخدمين والصلاحيات" },
      { href: "/users-sessions", title: "سجل اوقات المستخدمين" },
      { href: "/search-history", title: "سجل البحث" },
      { href: "/names-history", title: "سجل الأسماء" },
      { href: "/truck-history", title: "سجل النقلات" },
      { href: "/tool-history", title: "سجل العدة" },
      { href: "/treasury-history", title: "سجل الخزينة" },
      { href: "/backUp", title: "نسخة احتياطية" },
    ],
  },
  {
    icon: MdOutlineSupportAgent,
    list: "الدعم الفني",
    items: [
      { blank: "https://www.owltecheg.com", title: "الموقع الرسمي" },
      { href: "/brief", title: "شرح النظام" },
      { href: "/maintain", title: "الصيانة" },
      {
        blank: "http://www.oboormarket.org.eg/prices_today.aspx",
        title: "بورصة الأسعار اليومية",
      },
      { blank: "https://workspace.eta.gov.eg/", title: "الفاتورة الالكترونية" },
    ],
  },
  {
    icon: Settings,
    list: "الاعدادات",
    items: [
      {
        href: "/settings",
        title: "الاعدادات",
      },
      // { href: '/address', title: 'المحافظات والمدن' },
    ],
  },
];
const lists1 = [
  {
    icon: HomeOutlinedIcon,
    list: "بيانات أساسية",
    items: [
      { href: "/products", title: "اضف الصنف" },
      { href: "/movementTools", title: "اضف العدة" },
      { href: "/collecting-address", title: "اضف مكان التحصيل" },
      { href: "/add-partner/client", title: "اضف عميل   " },
      { href: "/add-partner/seller", title: "اضف بائع" },
      { href: "/partners", title: "قائمة  الاسماء " },
    ],
  },
  {
    icon: LocalShippingOutlinedIcon,
    list: "النقلات",
    items: [
      { href: "/loads", title: "اضف نقلة" },
      { href: "/daily-trucks", title: "نقلات اليوم" },
      { href: "/remaining-trucks", title: "نقلات متبقية" },
      { href: "/completed-trucks", title: "نقلات منتهية" },
    ],
  },
  {
    icon: FileCopyOutlinedIcon,
    list: "الفواتير",
    items: [
      { href: "/sellers-invoices", title: "فواتير البائعين" },
      { href: "/commission-invoices", title: "فواتير العموﻻت" },
      { href: "/purchase-invoices", title: "فواتير المشتريات" },
    ],
  },
  {
    icon: PeopleAltOutlinedIcon,
    list: "العملاء",
    items: [
      { href: "/profiles/clients", title: "حسابات العملاء" },
      { href: "/clients-collecting-addresses", title: "كشوف تحصيل العملاء" },
      { href: "/clients-arrears", title: "متأخرات العملاء" },
    ],
  },
  {
    icon: PeopleAltIcon,
    list: "البائعين",
    items: [
      { href: "/profiles/sellers", title: "حسابات البائعين" },
      { href: "/buyers-collecting-addresses", title: "كشوف تحصيل البائعين" },
      { href: "/buyers-arrears", title: "متأخرات البائعين" },
      { href: "/tools-arrears", title: "متأخرات العدة" },
    ],
  },
  {
    icon: Package,
    list: "العدة و الفوارغ",
    items: [
      { href: "/tools-out", title: "أضف خارج" },
      { href: "/tools-in", title: "أضف تحصيل" },
      { href: "/damage-tools", title: "أضف هالك" },
      { href: "/clients-reports", title: "تقارير العملاء" },
      { href: "/sellers-reports", title: "تقارير البائعين" },
      { href: "/tools-general-reports", title: "تقارير عامة" },
    ],
  },
  {
    icon: MoneyOutlinedIcon,
    list: "الخزينة",
    items: [
      { href: "/add-deposit", title: "اضف تحصيل" },
      { href: "/add-expenses", title: "اضف مصروفات" },
      { href: "/deposits", title: "التحصيل" },
      { href: "/search-expenses", title: "المصروفات" },
      { href: "/deposits-reports", title: "تقارير التحصيل" },
      { href: "/reports-expenses", title: "تقارير المصروفات" },
    ],
  },
  {
    icon: MonetizationOnOutlinedIcon,
    list: "الارباح",
    items: [
      { href: "/general-profits", title: "أرباح عامة" },
      { href: "/daily-profits", title: "أرباح يومية" },
      { href: "/monthly-profits", title: "أرباح شهرية" },
      { href: "/annual-profits", title: "أرباح سنوية" },
    ],
  },
  {
    icon: AccessTimeOutlinedIcon,
    list: "التقارير",
    items: [
      { href: "/general-reports", title: "تقارير مديونية" },
      { href: "/daily-reports", title: "تقارير يومية" },
      { href: "/monthly-reports", title: "تقارير شهرية" },
      { href: "/annual-reports", title: "تقارير سنوية" },
    ],
  },
  {
    icon: WorkOutlineOutlinedIcon,
    list: "الموظفين",
    items: [
      { href: "/add-employee", title: "اضف موظف" },
      // { href: "/add-job", title: "اضف وظيفة" },
      // { href: "/add-expenses/staff", title: "سلف موظفين" },
      { href: "/profiles/staff", title: "حسابات موظفين" },
    ],
  },
  {
    icon: GroupAddOutlinedIcon,
    list: "المستخدمين",
    items: [
      { href: "/signup", title: "اضف مستخدم" },
      { href: "/users", title: "المستخدمين والصلاحيات" },
      { href: "/users-sessions", title: "سجل اوقات المستخدمين" },
      { href: "/search-history", title: "سجل البحث" },
      { href: "/truck-history", title: "سجل النقلات" },
      { href: "/tool-history", title: "سجل العدة" },
      { href: "/treasury-history", title: "سجل الخزينة" },
    ],
  },
  {
    icon: MdOutlineSupportAgent,
    list: "الدعم الفني",
    items: [
      { blank: "https://www.owltecheg.com", title: "الموقع الرسمي" },
      { href: "/brief", title: "شرح النظام" },
      { href: "/maintain", title: "الصيانة" },
      {
        blank: "http://www.oboormarket.org.eg/prices_today.aspx",
        title: "بورصة الأسعار اليومية",
      },
      { blank: "https://workspace.eta.gov.eg/", title: "الفاتورة الالكترونية" },
    ],
  },
];

const NavItems = ({ isDrawerOpen }) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const decoded = jwt_decode(token);
  const id = decoded.PartnerId;
  useEffect(() => {
    if (role === "إدارة") {
      setData(lists);
    } else if (role === "مدير") {
      lists.splice(12, 1);
      setData(lists);
    } else if (role === "مشرف") {
      lists.splice(12, 1);
      lists.splice(10, 1);
      lists.splice(7, 2);
      setData(lists);
    } else if (role === "موظف") {
      lists1.splice(7, 4);
      setData(lists1);
    } else if (role === "محصل") {
      lists1.splice(0, 3);
      lists1.splice(2, 6);
      setData(lists1);
    } else if (role === "عميل_او_بائع") {
      lists1.splice(0, 11);
      setData(lists1);
      history.push(`/client-user-profile/${id}`);
    }
  }, []);

  return (
    <Fragment>
      <Collapse isopen={isDrawerOpen} lists={data} />
    </Fragment>
  );
};
export default NavItems;
